<template>
  <div class="">
    <div class="custom-tab-card">
      <div class="row">
        <CustomInput
          :className="'col-md-6'"
          :id="'employeeNationalNumber'"
          :value="employeeData.employeeNationalNumber"
          v-on:changeValue="employeeData.employeeNationalNumber = $event"
          :title="$t('nationalNumber')"
          :imgName="'nationalNumber.svg'"
          :disabledStatus="disabledStatus"
        />
        <CustomInput
          :className="'col-md-6'"
          :id="'employeePassportNumber'"
          :value="employeeData.employeePassportNumber"
          v-on:changeValue="employeeData.employeePassportNumber = $event"
          :title="$t('passportNumber')"
          :imgName="'passportNumber.svg'"
          :disabledStatus="disabledStatus"
        />
        <!-- <DatePicker
          :className="'col-md-6 col-lg-6'"
          :id="'employeeNationalNumberExpireDate'"
          :value="employeeData.employeeNationalNumberExpireDate"
          :title="$t('nationalNumberExpireDate')"
          :language="language"
          v-on:changeValue="
            employeeData.employeeNationalNumberExpireDate = $event
          "
        /> -->
        <!-- <DatePicker
          :className="'col-md-4 col-lg-4'"
          :id="'employeePassportExpireDate'"
          :value="employeeData.employeePassportExpireDate"
          :title="$t('passportExpireDate')"
          :language="language"
          v-on:changeValue="employeeData.employeePassportExpireDate = $event"
        /> -->

        <CustomInput
          :className="'col-md-6'"
          :id="'employeeDrivingLicenceNumber'"
          :value="employeeData.employeeDrivingLicenceNumber"
          v-on:changeValue="employeeData.employeeDrivingLicenceNumber = $event"
          :title="$t('drivingLicenceNumber')"
          :imgName="'drivingLicense.svg'"
          :disabledStatus="disabledStatus"
        />
        <DatePicker
          :className="'col-md-6'"
          :id="'employeeDrivingLicenceExpireDate'"
          :value="employeeData.employeeDrivingLicenceExpireDate"
          v-on:changeValue="
            employeeData.employeeDrivingLicenceExpireDate = $event
          "
          :title="$t('drivingLicenceExpireDate')"
          :language="language"
          :disabledStatus="disabledStatus"
        />

        <CustomSelectBox
          :className="'col-md-4'"
          :id="'employeeCitizenShipTypeToken'"
          :value="employeeData.employeeCitizenShipTypeToken"
          :options="employeeCitizenShipTypeTokenOptions"
          v-on:changeValue="employeeData.employeeCitizenShipTypeToken = $event"
          :title="$t('selectCitizenShipType')"
          :imgName="'citizenship.svg'"
          :disabledStatus="disabledStatus"
        />
        <DatePicker
          :className="'col-md-4'"
          :id="'employeeResidencyStartDate'"
          :value="employeeData.employeeResidencyStartDate"
          v-on:changeValue="employeeData.employeeResidencyStartDate = $event"
          :title="$t('employeeResidencyStartDate')"
          :language="language"
          :disabledStatus="disabledStatus"
        />
        <DatePicker
          :className="'col-md-4'"
          :id="'employeeResidencyEndDate'"
          :value="employeeData.employeeResidencyEndDate"
          v-on:changeValue="employeeData.employeeResidencyEndDate = $event"
          :title="$t('employeeResidencyEndDate')"
          :language="language"
          :disabledStatus="disabledStatus"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { DEFAULT_IMG } from "@/utils/constants";
import createToastMixin from "@/utils/create-toast-mixin";
import CustomInput from "@/components/general/CustomInput.vue";
import DatePicker from "@/components/general/DatePicker.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";

export default {
  name: "EmployeeIdentifiersCardsData",
  mixins: [createToastMixin],
  components: { CustomInput, DatePicker, CustomSelectBox },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      defaultImg: DEFAULT_IMG,
      employeeCitizenShipTypeTokenOptions: [],
    };
  },
  props: {
    employeeData: {
      type: Object,
    },
    disabledStatus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
    async getDialogOfCitizenShipType() {
      this.isLoading = true;
      this.employeeCitizenShipTypeTokenOptions = [];
      this.employeeCitizenShipTypeTokenOptions.push({
        value: "",
        text: this.$t("selectCitizenShipType"),
      });
      let itemsData =
        this.$store.getters.userData.constantLists.listCitizenShipType;
      for (let item in itemsData) {
        this.employeeCitizenShipTypeTokenOptions.push({
          value: itemsData[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            itemsData[item]["itemNameAr"],
            itemsData[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
  },
  watch: {},
  async created() {
    this.getDialogOfCitizenShipType();
  },
};
</script>

<style lang="scss"></style>
