<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div class="custom-tab-card">
      <div v-if="!disabledStatus" class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          :onerror="`this.src='${defaultImg}'`"
          alt="Image"
          @click="selectImage"
        ></div>
        <!-- @input="pickFile" -->
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>
      <div class="row">
        <TextArea
          :className="'col-md-4'"
          :id="'employeeAddressAr'"
          :value="employeeData.employeeAddressAr"
          v-on:changeValue="employeeData.employeeAddressAr = $event"
          :title="$t('employeeAddressAr')"
          :imgName="'address.svg'"
          :disabledStatus="disabledStatus"
        />
        <TextArea
          :className="'col-md-4'"
          :id="'employeeAddressEn'"
          :value="employeeData.employeeAddressEn"
          v-on:changeValue="employeeData.employeeAddressEn = $event"
          :title="$t('employeeAddressEn')"
          :imgName="'address.svg'"
          :disabledStatus="disabledStatus"
        />
        <TextArea
          :className="'col-md-4'"
          :id="'employeeAddressUnd'"
          :value="employeeData.employeeAddressUnd"
          v-on:changeValue="employeeData.employeeAddressUnd = $event"
          :title="$t('employeeAddressUnd')"
          :imgName="'address.svg'"
          :disabledStatus="disabledStatus"
        />

        <DatePicker
          :className="'col-md-6 col-lg-6'"
          :id="'employeeBirthdate'"
          :value="employeeData.employeeBirthdate"
          v-on:changeValue="employeeData.employeeBirthdate = $event"
          :title="$t('birthdate')"
          :language="language"
          :disabledStatus="disabledStatus"
        />
        <div class="form-label-group col-md-6">
          <b-form-select
            v-model="employeeData.employeeGenderToken"
            :options="employeeGenderTokenOptions"
            class="form-control"
          ></b-form-select>
          <img src="@/assets/images/gender.svg" />
        </div>

        <div class="form-label-group col-md-6">
          <b-form-select
            v-model="employeeData.employeeMaritalToken"
            :options="employeeMaritalTokenOptions"
            class="form-control"
          ></b-form-select>
          <img src="@/assets/images/maritalStatus.svg" />
        </div>
        <TextArea
          :className="'col-md-6'"
          :id="'employeeMaritalStatusNotes'"
          :value="employeeData.employeeMaritalStatusNotes"
          :title="$t('maritalStatusNotes')"
          :imgName="'notes.svg'"
          v-on:changeValue="employeeData.employeeMaritalStatusNotes = $event"
        />

        <div class="form-label-group col-md-6">
          <b-form-select
            v-model="employeeData.employeeMilitaryToken"
            :options="employeeMilitaryTokenOptions"
            class="form-control"
          ></b-form-select>
          <img src="@/assets/images/militaryStatus.svg" />
        </div>
        <TextArea
          :className="'col-md-6'"
          :id="'employeeMilitaryStatusNotes'"
          :value="employeeData.employeeMilitaryStatusNotes"
          :title="$t('militaryStatusNotes')"
          :imgName="'notes.svg'"
          v-on:changeValue="employeeData.employeeMilitaryStatusNotes = $event"
        />

        <div class="form-label-group col-md-4 col-lg-4">
          <b-form-select
            v-model="employeeData.employeeQualificationToken"
            :options="employeeQualificationTokenOptions"
            class="form-control"
          ></b-form-select>
          <img src="@/assets/images/qualifications.svg" />
        </div>
        <div class="form-label-group col-4">
          <b-form-select
            v-model="employeeData.employeeBloodTypeToken"
            :options="bloodTypeTokenOptions"
            class="form-control"
          ></b-form-select>
          <img src="@/assets/images/blood-types.svg" />
        </div>
        <div class="form-label-group col-4">
          <b-form-select
            v-model="employeeData.employeeScientificDegreeToken"
            :options="scientificDegreeTokenOptions"
            class="form-control"
          ></b-form-select>
          <img src="@/assets/images/scientific-degrees.svg" />
        </div>
        <CustomSelectBox
          :className="'col-md-6'"
          :id="'employeeBankToken'"
          :value="employeeData.employeeBankToken"
          :options="employeeBankTokenOptions"
          v-on:changeValue="employeeData.employeeBankToken = $event"
          :title="$t('selectBank')"
          :imgName="'banks.svg'"
        />
        <CustomInput
          :className="'col-md-6'"
          :id="'bankAccountNumber'"
          :value="employeeData.bankAccountNumber"
          :title="$t('employeeFields.bankAccountNumber')"
          :imgName="'number.svg'"
          v-on:changeValue="employeeData.bankAccountNumber = $event"
        />
        <TelPicker
          :className="'col-md-6'"
          :value="employeeData.employeeOtherCCWithCCName"
          v-on:changeCC="employeeData.employeeOtherCCWithCCName = $event"
        />
        <CustomInput
          :className="'col-md-6'"
          :id="'employeeOtherPhone'"
          :value="employeeData.employeeOtherPhone"
          :title="$t('phone2')"
          :imgName="'phone.svg'"
          v-on:changeValue="employeeData.employeeOtherPhone = $event"
        />

        <CustomInput
          :className="'col-md-4'"
          :id="'emergencyPersonNameAr'"
          :value="employeeData.emergencyPersonNameAr"
          :title="$t('employeeFields.emergencyPersonNameAr')"
          :imgName="'family.svg'"
          v-on:changeValue="employeeData.emergencyPersonNameAr = $event"
        />
        <CustomInput
          :className="'col-md-4'"
          :id="'emergencyPersonNameEn'"
          :value="employeeData.emergencyPersonNameEn"
          :title="$t('employeeFields.emergencyPersonNameEn')"
          :imgName="'family.svg'"
          v-on:changeValue="employeeData.emergencyPersonNameEn = $event"
        />
        <CustomInput
          :className="'col-md-4'"
          :id="'emergencyPersonNameUnd'"
          :value="employeeData.emergencyPersonNameUnd"
          :title="$t('employeeFields.emergencyPersonNameUnd')"
          :imgName="'family.svg'"
          v-on:changeValue="employeeData.emergencyPersonNameUnd = $event"
        />
        <TelPicker
          :className="'col-md-4'"
          :value="employeeData.emergencyPersonCCWithCCName"
          v-on:changeCC="employeeData.emergencyPersonCCWithCCName = $event"
        />
        <CustomInput
          :className="'col-md-4'"
          :id="'emergencyPersonPhone'"
          :value="employeeData.emergencyPersonPhone"
          :title="$t('employeeFields.emergencyPersonPhone')"
          :imgName="'phone.svg'"
          v-on:changeValue="employeeData.emergencyPersonPhone = $event"
        />
        <CustomInput
          :className="'col-md-4'"
          :id="'emergencyPersonEmail'"
          :value="employeeData.emergencyPersonEmail"
          :title="$t('employeeFields.emergencyPersonEmail')"
          :imgName="'email.svg'"
          v-on:changeValue="employeeData.emergencyPersonEmail = $event"
        />
        <CustomSelectBox
          :className="'col-md-12'"
          :id="'emergencyPersonRelativeRelationToken'"
          :value="employeeData.emergencyPersonRelativeRelationToken"
          :options="relativeRelationTokenOptions"
          v-on:changeValue="
            employeeData.emergencyPersonRelativeRelationToken = $event
          "
          :title="$t('employeeFields.selectEmergencyPersonRelativeRelation')"
          :imgName="'family.svg'"
        />
        <div class="form-label-group col-md-6">
          <b-form-select
            v-model="employeeData.employeeInsuranceCompanyToken"
            :options="employeeInsuranceCompanyTokenOptions"
            class="form-control"
          ></b-form-select>
          <img src="@/assets/images/type.svg" />
        </div>
        <CustomInput
          :className="'col-md-6'"
          :id="'employeeInsuranceNumber'"
          :value="employeeData.employeeInsuranceNumber"
          :title="$t('insuranceNumber')"
          :imgName="'number.svg'"
          v-on:changeValue="employeeData.employeeInsuranceNumber = $event"
        />

        <CustomSelectBox
          :className="employeeData.countingTypeToken ? 'col-md-6' : 'col-md-12'"
          :id="'countingTypeToken'"
          :value="employeeData.countingTypeToken"
          :options="countingTypeTokenOptions"
          v-on:changeValue="employeeData.countingTypeToken = $event"
          :title="$t('employeeFields.selectCountingType')"
          :imgName="'family.svg'"
        />
        <template v-if="employeeData.countingTypeToken">
          <CustomInput
            :className="'col-md-6'"
            :id="'familyNumber'"
            :value="employeeData.familyNumber"
            :title="$t('employeeFields.familyNumber')"
            :imgName="'number.svg'"
            v-on:changeValue="employeeData.familyNumber = $event"
          />
          <CustomSelectBox
            :className="'col-md-12'"
            :id="'employeeRelativeRelationToken'"
            :value="employeeData.employeeRelativeRelationToken"
            :options="relativeRelationTokenOptions"
            v-on:changeValue="
              employeeData.employeeRelativeRelationToken = $event
            "
            :title="$t('employeeFields.selectEmployeeRelativeRelation')"
            :imgName="'family.svg'"
          />
        </template>

        <div class="form-label-group col-md-6">
          <b-form-select
            v-model="employeeData.employeeCountryToken"
            :options="employeeCountryTokenOptions"
            @change="changeCountry()"
            class="form-control"
          ></b-form-select>
          <img src="@/assets/images/country.svg" />
        </div>
        <div class="form-label-group col-md-6">
          <b-form-select
            v-model="employeeData.employeeGovernateToken"
            :options="employeeGovernateTokenOptions"
            @change="changeGovernate()"
            class="form-control"
          ></b-form-select>
          <img src="@/assets/images/governate.svg" />
        </div>
        <div class="form-label-group col-md-6">
          <b-form-select
            v-model="employeeData.employeeCityToken"
            :options="employeeCityTokenOptions"
            @change="changeCity()"
            class="form-control"
          ></b-form-select>
          <img src="@/assets/images/city.svg" />
        </div>
        <div class="form-label-group col-md-6">
          <b-form-select
            v-model="employeeData.employeeDistrictToken"
            :options="employeeDistrictTokenOptions"
            class="form-control"
          ></b-form-select>
          <img src="@/assets/images/district.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { STATUS, DEFAULT_IMG, IMPOSSIBLE_TOKEN } from "@/utils/constants";
import axios from "axios";
import DatePicker from "@/components/general/DatePicker.vue";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import TelPicker from "@/components/general/TelPicker.vue";
import RelativeRelation from "@/models/settings/settingsOther/relativeRelations/RelativeRelation";
import Bank from "@/models/settings/settingsOther/banks/Bank";

export default {
  name: "EmployeePersonalData",
  watch: {
    "employeeData.employeeOtherCCWithCCName": function (val) {
      let cc = val.split(" ");
      this.employeeData.employeeOtherCountryCode = cc[1];
      this.employeeData.employeeOtherCountryCodeName = cc[0];
    },
    "employeeData.emergencyPersonCCWithCCName": function (val) {
      let cc = val.split(" ");
      this.employeeData.emergencyPersonCountryCode = cc[1];
      this.employeeData.emergencyPersonCountryCodeName = cc[0];
    },
  },
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    TextArea,
    DatePicker,
    CustomInput,
    CustomSelectBox,
    TelPicker,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      defaultImg: DEFAULT_IMG,

      employeeGenderTokenOptions: [],
      employeeQualificationTokenOptions: [],
      employeeMaritalTokenOptions: [],
      employeeMilitaryTokenOptions: [],
      bloodTypeTokenOptions: [],
      relativeRelation: new RelativeRelation(),
      relativeRelationTokenOptions: [],
      countingTypeTokenOptions: [],
      employeeCountryTokenOptions: [],
      employeeGovernateTokenOptions: [],
      employeeCityTokenOptions: [],
      employeeDistrictTokenOptions: [],
      employeeInsuranceCompanyTokenOptions: [],
      bank: new Bank(),
      employeeBankTokenOptions: [],
    };
  },
  props: {
    employeeData: {
      type: Object,
    },
    imageSrc: {
      type: String,
      default: "",
    },
    disabledStatus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.employeeData.image = file[0];
      } else {
        this.imageSrc = DEFAULT_IMG;
        this.employeeData.image = "";
      }
      // console.log(this.imageSrc);
    },
    async getDialogOfBanks() {
      this.isLoading = true;
      this.employeeBankTokenOptions = [];
      this.employeeBankTokenOptions.push({
        value: "",
        text: this.$t("selectBank"),
      });
      try {
        const response = await this.bank.getDialogOfBanks(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeBankTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfInsuranceCompanies() {
      this.isLoading = true;
      this.employeeInsuranceCompanyTokenOptions = [];
      this.employeeInsuranceCompanyTokenOptions.push({
        value: "",
        text: this.$t("selectInsuranceCompany"),
      });
      try {
        const response = await axios.get(
          `/InsuranceCompanies/GetDialogOfInsuranceCompanies?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeInsuranceCompanyTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async changeCountry() {
      this.employeeData.employeeGovernateToken = "";
      this.employeeData.employeeCityToken = "";
      this.employeeData.employeeDistrictToken = "";
      this.getDialogOfGovernates(this.employeeData.employeeCountryToken);
      this.getDialogOfCities(IMPOSSIBLE_TOKEN);
      this.getDialogOfDistricts(IMPOSSIBLE_TOKEN);
    },
    async changeGovernate() {
      this.employeeData.employeeCityToken = "";
      this.employeeData.employeeDistrictToken = "";
      this.getDialogOfCities(this.employeeData.employeeGovernateToken);
      this.getDialogOfDistricts(IMPOSSIBLE_TOKEN);
    },
    async changeCity() {
      this.employeeData.employeeDistrictToken = "";
      this.getDialogOfDistricts(this.employeeData.employeeCityToken);
    },
    async getDialogOfRelativeRelations() {
      this.isLoading = true;
      this.relativeRelationTokenOptions = [];
      this.relativeRelationTokenOptions.push({
        value: "",
        text: this.$t("relativeRelations.select"),
      });
      try {
        const response =
          await this.relativeRelation.getDialogOfRelativeRelations(
            this.language,
            this.userAuthorizeToken
          );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.relativeRelationTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.relativeRelationTokenOptions = null;
      }
      this.isLoading = false;
    },
    async getDialogOfCountingTypes() {
      this.isLoading = true;
      this.countingTypeTokenOptions = [];
      this.countingTypeTokenOptions.push({
        value: "",
        text: this.$t("employeeFields.selectCountingType"),
      });
      let genders = this.$store.getters.userData.constantLists.listCountingType;
      for (let item in genders) {
        this.countingTypeTokenOptions.push({
          value: genders[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            genders[item]["itemNameAr"],
            genders[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfGenders() {
      this.isLoading = true;
      this.employeeGenderTokenOptions = [];
      this.employeeGenderTokenOptions.push({
        value: "",
        text: this.$t("selectGender"),
      });
      let genders = this.$store.getters.userData.constantLists.listGender;
      for (let item in genders) {
        this.employeeGenderTokenOptions.push({
          value: genders[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            genders[item]["itemNameAr"],
            genders[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfMaritals() {
      this.isLoading = true;
      this.employeeMaritalTokenOptions = [];
      this.employeeMaritalTokenOptions.push({
        value: "",
        text: this.$t("selectMaritalStatus"),
      });
      let maritals = this.$store.getters.userData.constantLists.listMarital;
      for (let item in maritals) {
        this.employeeMaritalTokenOptions.push({
          value: maritals[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            maritals[item]["itemNameAr"],
            maritals[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfMilitaries() {
      this.isLoading = true;
      this.employeeMilitaryTokenOptions = [];
      this.employeeMilitaryTokenOptions.push({
        value: "",
        text: this.$t("selectMilitaryStatus"),
      });
      let militaries = this.$store.getters.userData.constantLists.listMilitary;
      for (let item in militaries) {
        this.employeeMilitaryTokenOptions.push({
          value: militaries[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            militaries[item]["itemNameAr"],
            militaries[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfBloodTypes() {
      this.isLoading = true;
      this.bloodTypeTokenOptions = [];
      this.bloodTypeTokenOptions.push({
        value: "",
        text: this.$t("selectBloodType"),
      });
      try {
        const response = await axios.get(
          `/BloodTypes/GetDialogOfBloodTypes?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.bloodTypeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.bloodTypeTokenOptions = null;
      }
      this.isLoading = false;
    },
    async getDialogOfScientificDegrees() {
      this.isLoading = true;
      this.scientificDegreeTokenOptions = [];
      this.scientificDegreeTokenOptions.push({
        value: "",
        text: this.$t("selectScientificDegree"),
      });
      try {
        const response = await axios.get(
          `/ScientificDegrees/GetDialogOfScientificDegrees?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.scientificDegreeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.scientificDegreeTokenOptions = null;
      }
      this.isLoading = false;
    },

    async getDialogOfQualifications() {
      this.isLoading = true;
      this.employeeQualificationTokenOptions = [];
      this.employeeQualificationTokenOptions.push({
        value: "",
        text: this.$t("selectQualification"),
      });
      try {
        const response = await axios.get(
          `/Qualifications/GetDialogOfQualifications?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeQualificationTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.employeesData = null;
      }
      this.isLoading = false;
    },

    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
    async getDialogOfCountries() {
      this.isLoading = true;
      this.employeeCountryTokenOptions = [];
      this.employeeCountryTokenOptions.push({
        value: "",
        text: this.$t("selectCountry"),
      });
      try {
        const response = await axios.get(
          `/Countries/GetDialogOfCountries?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeCountryTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfGovernates(countryToken) {
      this.isLoading = true;
      countryToken = countryToken ? countryToken : IMPOSSIBLE_TOKEN;
      this.employeeGovernateTokenOptions = [];
      this.employeeGovernateTokenOptions.push({
        value: "",
        text: this.$t("selectGovernate"),
      });
      try {
        const response = await axios.get(
          `/Governates/GetDialogOfGovernates?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&filterStatus=true&countryToken=${countryToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeGovernateTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfCities(governateToken) {
      this.isLoading = true;
      governateToken = governateToken ? governateToken : IMPOSSIBLE_TOKEN;
      this.employeeCityTokenOptions = [];
      this.employeeCityTokenOptions.push({
        value: "",
        text: this.$t("selectCity"),
      });
      try {
        const response = await axios.get(
          `/Cities/GetDialogOfCities?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&filterStatus=true&governateToken=${governateToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeCityTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfDistricts(cityToken) {
      this.isLoading = true;
      cityToken = cityToken ? cityToken : IMPOSSIBLE_TOKEN;
      this.employeeDistrictTokenOptions = [];
      this.employeeDistrictTokenOptions.push({
        value: "",
        text: this.$t("selectDistrict"),
      });
      try {
        const response = await axios.get(
          `/Districts/GetDialogOfDistricts?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&filterStatus=true&cityToken=${cityToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeDistrictTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getDialogOfGenders();
    this.getDialogOfMaritals();
    this.getDialogOfMilitaries();
    this.getDialogOfQualifications();
    this.getDialogOfBloodTypes();
    this.getDialogOfScientificDegrees();
    this.getDialogOfRelativeRelations();
    this.getDialogOfCountingTypes();
    this.getDialogOfCountries();
    this.getDialogOfGovernates();
    this.getDialogOfCities();
    this.getDialogOfDistricts();
    this.getDialogOfInsuranceCompanies();
    this.getDialogOfBanks();
  },
};
</script>

<style lang="scss"></style>
